import {UNIT_LIST} from '../Actions/actionTypes';
const initState = {status:false,list:[]}

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case UNIT_LIST:
            return {
                ...state,
                list: action.payload,
                status:action.status
            }
        default:
            return state
    }
}

export default characterReducer;
