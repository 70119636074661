import axios from "axios"
import toastr from "../_Common/toaster";
import {userLogoutAction} from '../FrontEnd/Actions/User.action';
import history from '../FrontEnd/routes/history';
var CancelToken = axios.CancelToken;
let wj_cancel ;
let resp;

export const request = async (path, data, method, headers) => {

	
	if(wj_cancel && resp && resp.config && resp.config.url == `${process.env.REACT_APP_API_URL}/${path}`){
			wj_cancel && wj_cancel(); 
	}
	var options = { 
		method: method,
		url: `${process.env.REACT_APP_API_URL}/${path}`,
		headers: {
			"Content-Type":"application/json",
			"Authorization":"key@123",
			...headers
		},
		dataType: 'json',
		cancelToken: new CancelToken(function executor(c) {            
            wj_cancel = c;
        })
	};
	if(localStorage.getItem("mpWebStatus") || localStorage.getItem("mpAdminStatus")){
		options.headers["Authorization"] = localStorage.getItem("jwtToken");
	}else{
		options.headers["Authorization"] = "key@123";
	}
	if(method==="GET"){
		options["params"] = data;
	}
	else{
		options["data"] = data;
	}
	
	let responce = axios(options);
	
	let result =  await responce.then(res1=>{
		resp = res1;
		if(res1.data.code===402){
			localStorage.clear();
		    toastr.show({message: res1.data.msg,intent: "danger"});
		    window.location = "/";
		    //history.push('/');
		}

		return resp.data;
	}).catch(function (error) {
		if (error.response) {
		  	// The request was made and the server responded with a status code
		  	// that falls out of the range of 2xx
		  	//console.log('1111---',error.response.data);
			//console.log(error.response.status);
			//console.log(error.response.headers);

		  	return error.response.data;
		}
	});
	
	return result;
}

export const postRequest = (path, data, headers = {}) => request(path, data, "POST", headers)
export const putRequest = (path, data) => request(path, data, "PUT")
export const getRequest = (path, data) => request(path, data, "GET")
export const deleteRequest = (path, data) => request(path, data, "DELETE")
