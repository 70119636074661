import React,{useEffect} from 'react';
import { useSelector } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import renderHTML from 'react-render-html';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {siteDetail} from '../../../_Constants/app.constants';

const PrivacyPolicyPage = ({history}) => {
  const privacyPolicyForm = useSelector(state => {
    if(state && state.institute && state.institute.adminFooter){
       return (state.institute.adminFooter)
    }
  });

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
  },[]);

  return (
    <div>
      <MetaTags>
        <title>Privacy Policy</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </MetaTags>

      </div>
  );
}
export default withLocalize(PrivacyPolicyPage);
