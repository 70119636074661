import $ from 'jquery';

export const FormError=(errors)=>{
    let formatedErrors = {};
    for(let x in errors){
        formatedErrors[errors[x].param] = errors[x].msg;
    }
    return formatedErrors;
}

export const ShowFormErrors=(hookError,formError,field)=>{
    if(Object.keys(hookError).length === 0){
        $('.form-control').removeClass("requiredClass");
    }
    //console.log('==', hookError);
    for(let x in hookError){
        let array = Object.keys(hookError)
        if (array.indexOf(field) > -1) {
            $('input[name="'+field+'"]').addClass("requiredClass");
            if(field === 'profileImg'){
                window.scrollTo(0, 200);
            }            
        }else{
            $('input[name="'+field+'"]').removeClass("requiredClass");
        }
    }
    if(hookError[field] && hookError[field].message){
        return hookError[field].message;
    }
    else if(formError[field]){
        return formError[field];
    }
}