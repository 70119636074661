import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './LoginPage.css';
import { AnchorButton, Button, Code, H5, Intent, Switch } from "@blueprintjs/core";
import { Example, handleBooleanChange, handleStringChange } from "@blueprintjs/docs-theme";
import { withLocalize, Translate } from 'react-localize-redux'
import LanguageToggle from '../../../_Locales//LanguageToggle';
import { IntentSelect } from "../../../_Common/intentSelect";
class LoginPage extends React.Component<any, any> {
    constructor(props) {
      super(props);      
      this.state = {
        active: false,
        disabled: false,
        iconOnly: false,
        intent: Intent.NONE,
        large: false,
        loading: false,
        minimal: false,
        wiggling: false,
      };
      this.handleActiveChange = handleBooleanChange(active => this.setState({ active }));
      this.handleDisabledChange = handleBooleanChange(disabled => this.setState({ disabled }));
      this.handleIconOnlyChange = handleBooleanChange(iconOnly => this.setState({ iconOnly }));
      this.handleLargeChange = handleBooleanChange(large => this.setState({ large }));
      this.handleLoadingChange = handleBooleanChange(loading => this.setState({ loading }));
      this.handleMinimalChange = handleBooleanChange(minimal => this.setState({ minimal }));
      this.handleIntentChange = handleStringChange((intent: Intent) => this.setState({ intent }));      
      this.wiggleTimeoutId = null;    
    };
  componentWillUnmount() {
    
    window.clearTimeout(this.wiggleTimeoutId);
  }
  beginWiggling = () => {
      window.clearTimeout(this.wiggleTimeoutId);
      this.setState({ wiggling: true });
      this.wiggleTimeoutId = window.setTimeout(() => this.setState({ wiggling: false }), 300);
  };  
  render() {    
    const { iconOnly, wiggling, ...buttonProps } = this.state;  
    const options = (
            <span>
                <H5>Props</H5>
                <Switch label="Active" checked={this.state.active} onChange={this.handleActiveChange} />
                <Switch label="Disabled" checked={this.state.disabled} onChange={this.handleDisabledChange} />
                <Switch label="Large" checked={this.state.large} onChange={this.handleLargeChange} />
                <Switch label="Loading" checked={this.state.loading} onChange={this.handleLoadingChange} />
                <Switch label="Minimal" checked={this.state.minimal} onChange={this.handleMinimalChange} />
                <IntentSelect intent={this.state.intent} onChange={this.handleIntentChange} />
                <H5>Example</H5>
                <Switch label="Icons only" checked={this.state.iconOnly} onChange={this.handleIconOnlyChange} />
            </span>
        );   
    return (
      <div>
      <header>
          <LanguageToggle />   
          <Translate id={`HOME`} />
      </header>           
      <p><h1><Translate id={`WELCOME2`} /></h1> </p>
      <Example options={options} {...this.props} data-example-id="ButtonsExample">                 
                <div>
                    <p>
                        <Code>Button</Code>
                    </p>
                    <Button
                        className={this.state.wiggling ? "docs-wiggle" : ""}
                        icon="refresh"
                        onClick={this.beginWiggling}
                        {...buttonProps}
                    >
                        {!iconOnly && "Click to wiggle"}
                    </Button>
                </div>
                <div>
                    <p>
                        <Code>AnchorButton</Code>
                    </p>
                    <AnchorButton
                        href="#core/components/button"
                        icon="duplicate"
                        rightIcon="share"
                        target="_blank"
                        text={iconOnly ? undefined : "Duplicate this page"}
                        {...buttonProps}
                    />
                </div>
            </Example>
           </div>
    );
  }
}

function mapStateToProps(state) {
  return state
}
export default withLocalize(withRouter(connect(mapStateToProps)(LoginPage)));


