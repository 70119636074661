const initState = {}
export default (state = initState, action = {}) => {
    switch (action.type) {
        case "TABLE_DETAILS_CHANGED":
            return {
                ...state,
                ...action.payload
            };        
        default:
            return state
    }
}

