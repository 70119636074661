import {CATEGORY_LIST, SUB_CATEGORY_LIST} from '../Actions/actionTypes';
const initState = {status:false,list:[],categoryList:[]}

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case CATEGORY_LIST:
            return {
                ...state,
                list: action.payload,
                status:action.status
            }
        case SUB_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload
            }
        default:
            return state
    }
}

export default characterReducer;
