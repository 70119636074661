import React from 'react';
import  {Router, Route, Redirect }  from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import {routerMiddleware} from 'react-router-redux';
import history from './history';
import reducer from '../Reducers'
import HomePage from '../../FrontEnd/Components/HomePage/HomePage';
import HeaderPage from '../../FrontEnd/Components/HeaderPage/HeaderPage.js';
import FooterPage from '../../FrontEnd/Components/FooterPage/FooterPage.js';

import PrivacyPolicyPage from '../../FrontEnd/Components/SitePages/PrivacyPolicyPage';
import TermsandConditionPage from '../../FrontEnd/Components/SitePages/TermsandConditionPage';


import NotFoundPage from '../../_Common/accessDenied';
  
 //let data = (localStorage.getItem("permissions"))?atob(localStorage.getItem("permissions")):'';
 //let permission = (data!=='' && data!=='undefined')?JSON.parse(data):[];
 export const makeMainRoutes = () => {
    const historyMiddleware = routerMiddleware(history);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducer,
        composeEnhancers(
            applyMiddleware(historyMiddleware, thunk)
        )
    )

    return (
        <Provider store={store}>
            <Router history={history}>
                <div className="routesBox">
                    <LocalizeProvider>
                        {/* <HeaderPage  /> */}
                        <Route exact path="/" render={(props) => <HomePage  {...props} />} />

                        <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicyPage  {...props} />} />
                        <Route exact path="/terms-condition" render={(props) => <TermsandConditionPage  {...props} />} />
                    
                        {/*<Route path="/404" component={NotFoundPage} />
                        <Redirect to="/"/>*/}

                        <FooterPage />
                    </LocalizeProvider>
                </div>
            </Router>
        </Provider>
    );
}
