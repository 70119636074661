import {PRODUCT_LIST,CART_COUNT} from '../Actions/actionTypes';
const initState = []

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case PRODUCT_LIST:
            return {
                ...state,
                list: action.payload
            }
        case CART_COUNT:        	
            return {
                ...state,
                cartCount: action.payload
            }
        default:
            return state
    }
}

export default characterReducer;
