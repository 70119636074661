import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import category from './Category.reducer'
import institute from './Institute.reducer'
import student from './Student.reducer'
import unit from './Unit.reducer'
import cart from './Cart.reducer'

const reducerFrontEnd = combineReducers({
   router:routerReducer,
   category:category,
   institute:institute,
   student:student,
   unit:unit,
   cart:cart,
})
export default reducerFrontEnd
