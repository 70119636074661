import React,{useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';

const styleComp = {
    //'position': 'fixed',
    'z-index': '999999',
    'width': '100%',
    'top': '0px',
    'border': '0px none',
    'background-color': '#ececec',
    'height': '600px',
    'opacity': '0.8',
    'color': 'rgb(238, 8, 8)',
    'text-align': 'center',
    'margin': '0 auto',
    'font-size': '32px'
}

const AccessDenied = (access=null) => {
    useEffect(()=>{
        document.documentElement.scrollTop = 0;
    },[]);

    return (
        <section className="about_page pad-reggis inner_pagewrap">
            <div className="container">
                <div className="row white_box">
                    <div className="col-lg-12 col-sm-12">
                        <div className="section_title page_ttl text-center">
                            <h3>400</h3>
                            <div className="decor2"></div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 text-left">
                        <h1>Access Denied</h1>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default withLocalize(AccessDenied);
