export const CATEGORY_LIST = "CATEGORY_LIST"

export const INSTITUTE_LIST = "INSTITUTE_LIST"

export const STUDENT_PROFILE = "STUDENT_PROFILE"

export const UNIT_LIST = "UNIT_LIST"

export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST"

export const USER_LIST = "USER_LIST"

export const PRODUCT_LIST = "PRODUCT_LIST"

export const CART_COUNT = "CART_COUNT"

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT"

export const QUIZ_LIST = "QUIZ_LIST"

export const INSTITUTE_DEATILS = "INSTITUTE_DEATILS"

export const INSTRUCTOR_LIST = "INSTRUCTOR_LIST"

export const ADMIN_FOOTER = "ADMIN_FOOTER"
