import React,{useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {siteDetail} from '../../../_Constants/app.constants';
import {getUserProfileAction} from '../../Actions/User.action';
import moment from 'moment';

const TermsandConditionPage = ({history}) => {
  const { id } =  useParams();
  const [userDetail,setUserDetail] = useState({});
  const [userType,setUserType] = useState(1);

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    if(id){
      getUserProfileDetil(id);
    }else if(localStorage.getItem('mpWebStatus')){
      getUserProfileDetil(localStorage.getItem('_id'));
    }
  },[]);

  const getUserProfileDetil = async(id)=>{
    let userDetail = await getUserProfileAction({userId: id});
    if(userDetail && userDetail.data!==''){
      if((userDetail.data.userType==='CLINIC') || (userDetail.data.userType==='PT' && userDetail.data.clinicId === '')){
        setUserType(2);
      }else if(userDetail.data.userType==='PT'){
        setUserType(3);
      }else{
        setUserType(1);
      }
      setUserDetail(userDetail.data);
    }
  }  

  return (
    <div>
      <MetaTags>
        <title>Terms and Conditions</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </MetaTags>

      <section className="terms_con__page pad-reggis inner_pagewrap">
          <BreadcrumbsComp bcNewItems={[
            { path: '/', breadcrumb: "Home" },
            { path: '/terms-condition', breadcrumb: 'Terms Conditions'},
          ]} />
        
        <div className="container">
          <div className="white_box">
            <div className="col-lg-12 col-sm-12">
              <div className="section_title page_ttl text-center">
                <h3> Terms and Conditions </h3>
                <div className="decor2"></div>
              </div>
            </div>
              <div className="cotent-statics">
              
                </div>
             
           </div>
          </div>
      </section>
    </div>
  );
}
export default withLocalize(TermsandConditionPage);
