import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { getFeedbackListAction } from '../../Actions/User.action';
import { Dialog } from "@blueprintjs/core";
import MetaTags from 'react-meta-tags';
import LoginPageComponent from './Login/LoginPage.js';
import SignUp from '../SignUp/SignUp';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './style.css'
const testimonialState = {
  responsive: {
    0: {
      items: 1,
    },
    769: {
      items: 2,
    },
  },
}

const HomePage = ({ history }) => {
  const dispatch = useDispatch();
  const [isSignupForm, setIsSignupForm] = useState(false);
  const [feedbackList, setFeedbackList] = useState({});

  const getFeedbackListFunction = async () => {
    let feedbackData = await getFeedbackListAction();
    setFeedbackList(feedbackData);
  }

  useEffect(() => {
    getFeedbackListFunction();
  }, []);

  return (
    <div className="slide-er">
      <div id="app">
        <div data-v-13a48093="" className="home">
          <div data-v-13a48093="" data-app="true" className="application theme--light" id="inspire">
            <div className="application--wrap">
              <main data-v-13a48093="" className="content" data-booted="true" style={{padding: "0px"}}>
                <div className="content--wrap">
                  <div data-v-13a48093="" className="container fluid fill-height">
                    <div data-v-13a48093="" className="layout justify-center align-center">
                      <div data-v-13a48093="" className="flex xs3"></div>
                      <div data-v-13a48093="" className="flex xs6 text-xs-center">
                        <div data-v-13a48093="">
                          <img data-v-13a48093="" src="../static/img/MPTrekz2.207952c.png" style={{width:"40%"}} />
                          <div data-v-13a48093="" className="time-for display-3 white--text">TIME FOR A NEW ADVENTURE</div>
                          <div data-v-13a48093="" className="flex white--text align-center text-xs-center justify-center">
                            Twenty years from now you will be more disappointed by the things you didn’t do than by the ones you did do. So throw off the bowlines. Sail away from the safe harbor. Catch the trade
                            winds in your sails. Explore. Dream. Discover.
                          </div>
                        </div>
                        <br data-v-13a48093="" />
                        <br data-v-13a48093="" />
                        <br data-v-13a48093="" />
                        <img data-v-13a48093="" src="../static/img/iphone3.561a4fb.png" />
                        <div data-v-13a48093="" className="coming-soon display-1">COMING SOON</div>
                        <div data-v-13a48093="" className="copy-right white--text">© 2018 MPJourney</div>
                      </div>
                      <div data-v-13a48093="" className="flex xs3"></div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withLocalize(withRouter(HomePage));
