import React from 'react';
import  { Router, Route }  from 'react-router-dom';	
import { LocalizeProvider } from "react-localize-redux";
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import {routerMiddleware} from 'react-router-redux';
import reducer from '../Reducers'
import history from './history';
import LoginPage from '../../Institute/Components/LoginPage/LoginPage.js';;
export const makeMainRoutesInstitute = () => {
    const historyMiddleware = routerMiddleware(history);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const storeAdmin = createStore(  
      reducer,
      composeEnhancers(
        applyMiddleware(historyMiddleware, thunk)
      )
    )
    return (
        <Provider store={storeAdmin}>
            <Router history={history}>
                <div className="routesBox">
                    <LocalizeProvider>
                        <Route exact path="/admin"  render={(props) => <LoginPage {...props} />} />                       
                        <Route exact path="/admin/login"  render={(props) => <LoginPage {...props} />} />                       
                    </LocalizeProvider>
                </div>
            </Router>
        </Provider>
    );
}
