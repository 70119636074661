import {INSTITUTE_LIST, USER_LIST, QUIZ_LIST,INSTITUTE_DEATILS, INSTRUCTOR_LIST, ADMIN_FOOTER} from '../Actions/actionTypes';
const initState = {
                    status:false,
                    list:[],
                    userList:[],
                    quizList:[],
                }

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case INSTITUTE_LIST:
            return {
                ...state,
                list: action.payload
            }
        case USER_LIST:
            return {
                ...state,
                userList: action.payload,
                status:action.status
            }
        case QUIZ_LIST:
            return {
                ...state,
                quizList: action.payload,
                status:action.status
            }
        case INSTITUTE_DEATILS:
            return {
                ...state,
                insDetails:action.payload
            }
        case INSTRUCTOR_LIST:
            return {
                ...state,
                insList:action.payload,
                status:action.status
            }
        case ADMIN_FOOTER:
            return {
                ...state,
                adminFooter:action.payload,
                status:action.status
            }
        default:
            return state
    }
}

export default characterReducer;
