import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import { withLocalize } from 'react-localize-redux'
import {siteDetail} from '../../../_Constants/app.constants';
import './FooterPage.css';
import moment from 'moment';

const FooterPage = ({history}) => {

  return (
      <footer>
      </footer>
    );
}
export default withLocalize(withRouter(FooterPage));
