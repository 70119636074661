import {STUDENT_PROFILE} from '../Actions/actionTypes';
const initState = []

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case STUDENT_PROFILE:
            return {
                student_profile: action.payload
            }
        default:
            return {
                student_profile: {}
            }
    }
}

export default characterReducer;