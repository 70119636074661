import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import toastr from "../../../_Common/toaster";
import {userLogoutAction} from '../../Actions/User.action';
import { Dialog } from "@blueprintjs/core";
import SignUp from '../SignUp/SignUp';
import './HeaderPage.css';
import $ from 'jquery';

const HeaderPage = ({history}) => {
  const [isSignupForm,setIsSignupForm] = useState(false);
  const [isSignInForm,setIsSignInForm] = useState(false);
  const [homeScroll, setHomeScroll] = useState(true)

  const [isLogedIn,setIslogedIn] = useState(async function(){
    if(localStorage.getItem("jwtToken") && localStorage.getItem("mpWebStatus")===true){
      return true;
    }else{
      //history.push('/');
      // if(!localStorage.getItem("type") && !localStorage.getItem("jwtToken") ){
      //   history.push('/');
      // }
    }
    return false;
  });

  const onLogout = async (e) =>{
    e.preventDefault();
    $('#navbarResponsive').removeClass("show");
    let res = await userLogoutAction();
    if(res.success){
      localStorage.clear();
      setIslogedIn(false);
      toastr.show({message: 'Logout successfully',intent: "success"});
      history.push('/');
    }else{
     if(res.msg){
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  useEffect(()=>{
    $('.navbar-collapse .nav-link').on('click', function(){
      $('#navbarResponsive').removeClass("show");
    });
    window.addEventListener("scroll", function (event) {
      var scrollvalue = this.scrollY;
      if(scrollvalue > 50){
        setHomeScroll(false)
      }else{
        setHomeScroll(true)
      }
    });
  },[]);

  const changeMenuFunction = async (e, menuId, pname) =>{
    e.preventDefault();
    $('#navbarResponsive').removeClass("show");
    if(pname && pname!==''){
      history.push(pname);
      return false;
    }

    menuId = menuId?menuId:'home';
    if(window.location.pathname==='/'){
      $('html, body').animate({
         scrollTop: $('#'+menuId).offset().top-40
      }, 10);
    }else{
      history.push('/');
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $('#'+menuId).offset().top-40
        }, 10);
      },500);
    }
  }

  return (
    <header>
      <div className={[!homeScroll?'headerClassTwo':'', window.location.pathname==='/'?'home_header header_main':'header_main'].join(" ")}>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container">
            <Link to="#" className="navbar-brand js-scroll-trigger" onClick={e=>changeMenuFunction(e,'home')}>
              <img className="logo_white" src="../static/img/logo.png" alt="logo"/>
              <img className="logo_color1" src="../static/img/logo.png" alt="logo" />
              
            </Link>
            <button
              className="navbar-toggler navbar-toggler-right" type="button"
              data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
              aria-expanded="false" aria-label="Toggle navigation"><i className="fas fa-bars"></i>
            </button>
            
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto" id='toplinks'>
                
                {/*<li className="nav-item">
                  <Link to="/privacy-policy#" className="nav-link js-scroll-trigger">Privacy Policy</Link></li>
                <li className="nav-item">
                  <Link to='/terms-condition' className="nav-link js-scroll-trigger">Terms and Conditions</Link>
                </li>

                <li className="nav-item">
                  <Link to="/html/privacy-policy.html" className="nav-link js-scroll-trigger">Privacy Policy</Link></li>
                <li className="nav-item">
                  <Link to='/html/terms-condition.html' className="nav-link js-scroll-trigger">Terms and Conditions</Link>
                </li>*/}

                
                
              </ul>
            </div>
          
          </div>
        </nav>
      </div>
      
    </header>
  );
}
export default withLocalize(withRouter(HeaderPage));